import styled from 'styled-components';
import { FlexboxGrid, Loader, SelectPicker } from 'rsuite';
import { mq } from '../../styles/mediaQueries';
import { HeaderGradientBackground } from '../../styles/fragments';
import type { BrokerSelectItemName } from './types';
import { colors } from '../../styles/colors';

export const BrokerDescription = styled.div`
  color: white;
  font-size: 1.6em;
  font-weight: 600;
  max-width: 300px;
  margin-bottom: 20px;
`;

export const BrokerSelect = styled(SelectPicker)`
  .rs-picker-toggle.rs-btn.rs-btn-default.rs-btn-md {
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    opacity: 0.9;
    font-weight: 600;

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }

  @media screen and (${mq.tablet}) {
    margin-bottom: 20px;
  }
`;

export const BrokerPanelContainer = styled.div`
  ${HeaderGradientBackground};

  .rs-plaintext {
    color: white;
    font-size: 25px;
    font-weight: bold;
    max-width: 300px;
  }

  @media screen and (${mq.mobile}) {
    .rs-plaintext {
      font-size: 20px;
    }
  }
`;

export const BrokerItemIcon = styled.i<{
  $broker: BrokerSelectItemName;
}>`
  background-color: ${({ $broker }) =>
    $broker === 'any-broker' || $broker === 'not-existing'
      ? 'var(--i-secondary-dark)'
      : colors[$broker]};
  border-radius: 20%;
  background-image: ${({ $broker }) =>
    `url(/img/brokers/${$broker}-logo-white.png)`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  transition: all 0.2s ease-in-out;
`;

export const BrokerItemLoader = styled(Loader)`
  position: absolute;
  left: 0;
  background-color: var(--i-background-tone);
`;

export const Row = styled(FlexboxGrid.Item)`
  .rs-picker-toggle-wrapper {
    width: 100%;
  }

  &.rs-col {
    padding: 0;
  }
`;

export const BrokerItem = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;
