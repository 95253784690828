import styled from 'styled-components';
import { mq } from '../../styles/mediaQueries';

export const SectionContainer = styled.div`
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
  padding: 40px;

  @media screen and (${mq.mobile}) {
    padding: 20px;
  }
`;
