module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Investorean","name":"Investorean - stock screener and analytics","description":"Stock screener app and analytics for different brokers","lang":"en","start_url":"/","background_color":"#FAFAFF","theme_color":"#00234b","display":"fullscreen","icons":[{"src":"/favicon/v2/apple-touch-icon.png","type":"image/png","sizes":"180x180"},{"src":"/favicon/v2/favicon-32x32.png","type":"image/png","sizes":"32x32"},{"src":"/favicon/v2/favicon-16x16.png","type":"image/png","sizes":"16x16"},{"src":"/favicon/v2/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/v2/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicon/v2/maskable_icon_460x460.png","sizes":"460x460","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://investorean.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"5a7ae0e5587a718575db8ac8bb5fe38a","pageViews":null,"enableOnDevMode":true,"mixpanelConfig":{"persistence":"localStorage"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":true,"id":"GTM-MC37F2X","routeChangeEventName":"change-route-event","defaultDataLayer":{"type":"object","value":{}},"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
