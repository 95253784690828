import styled from 'styled-components';
import { mq } from '../../styles/mediaQueries';

export const Container = styled.div`
  min-height: calc(100vh - 70px - 51px - 27px);

  @media (${mq.mobile}) {
    min-height: calc(100vh - 70px - 186px - 27px);
  }
`;
