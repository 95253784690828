import type { StaticConfig, Stock } from '@investorean/types';
import type { Filter } from './types/filters';
import type { StockScreenerFieldNames } from './components/StocksScreener/types';

export const renderPrefix = ({
  prefix,
  currencyCode,
  staticConfig,
}: {
  prefix?: string;
  currencyCode: string | null;
  staticConfig?: StaticConfig;
}): string => {
  if (!prefix || !currencyCode || !staticConfig) {
    return '';
  }
  switch (prefix) {
    case '%currency%':
      return (
        staticConfig.stocks.currencies.find((c) => c.code === currencyCode)
          ?.symbol || ''
      );
    default:
      return prefix;
  }
};

export const selectorFilters: Filter[] = [
  {
    label: 'Lower',
    value: '<',
    type: 'value',
  },
  {
    label: 'Higher',
    value: '>',
    type: 'value',
  },
  {
    label: 'Equal',
    value: '=',
    type: 'value',
  },
  {
    label: 'Between',
    value: '&',
    type: 'range',
  },
];

export const weekTabs = [
  {
    label: 'below High',
    id: 1,
    slider: true,
    value: 'week52LowDiff',
  },
  {
    label: 'above High',
    id: 2,
    slider: true,
    value: 'week52HighDiff',
  },
  {
    label: 'new High',
    id: 3,
    slider: false,
    value: 'week52NewHigh',
  },
  {
    label: 'new Low',
    id: 4,
    slider: false,
    value: 'week52NewLow',
  },
];

export type StockLabelNames = keyof Stock | StockScreenerFieldNames;

export const stockFieldsLabels: {
  [name in StockLabelNames]: {
    label: string;
    labelHTML: string;
    prefix?: string;
    suffix?: string;
  };
} = {
  broker: {
    label: 'Broker',
    labelHTML: 'Broker',
  },
  symbol: {
    label: 'Ticker',
    labelHTML: 'Ticker',
  },
  symbolSorted: {
    label: 'Ticker',
    labelHTML: 'Ticker',
  },
  ticker: {
    label: 'Ticker',
    labelHTML: 'Ticker',
  },
  countryCode: {
    label: 'Country',
    labelHTML: 'Country',
  },
  currencyCode: {
    label: 'Currency',
    labelHTML: 'Currency',
  },
  companyName: {
    label: 'Company',
    labelHTML: 'Company',
  },
  exchange: {
    label: 'Exchange',
    labelHTML: 'Exchange',
  },
  sector: {
    label: 'Sector',
    labelHTML: 'Sector',
  },
  industry: {
    label: 'Industry',
    labelHTML: 'Industry',
  },
  marketCap: {
    label: 'Market Cap.',
    labelHTML: 'Market Cap.',
  },
  avg30Volume: {
    label: 'Avg. Volume (30D)',
    labelHTML: 'Avg. Volume (30D)',
  },
  avg10Volume: {
    label: 'Avg. Volume (10D)',
    labelHTML: 'Avg. Volume (10D)',
  },
  latestVolume: {
    label: 'Volume (last day)',
    labelHTML: 'Volume (last day)',
  },
  relativeVolume: {
    label: 'Relative Volume',
    labelHTML: 'Relative Volume',
  },
  price: {
    label: 'Price',
    labelHTML: 'Price',
  },
  priceUSD: {
    label: 'Price ($)',
    labelHTML: 'Price ($)',
  },
  day1ChangePercent: {
    label: '1D Chg. (%)',
    labelHTML: 'Price Change (1D)',
    suffix: '%',
  },
  day5ChangePercent: {
    label: '5D Chg. (%)',
    labelHTML: 'Price Change (5D)',
    suffix: '%',
  },
  day50MovingAvgDiff: {
    label: 'Diff to 50-Day SMA',
    labelHTML: 'Diff to 50-Day SMA',
    suffix: '%',
  },
  day200MovingAvgDiff: {
    label: 'Diff to 200-Day SMA',
    labelHTML: 'Diff to 200-Day SMA',
    suffix: '%',
  },
  description: {
    label: 'Description',
    labelHTML: 'Description',
  },
  nextEarningsDate: {
    label: 'Next Earnings Date',
    labelHTML: 'Next Earnings Date',
  },
  sharesOutstanding: {
    label: 'Shares Outstanding',
    labelHTML: 'Shares Outstanding',
  },
  employees: {
    label: 'Employees',
    labelHTML: 'Employees',
  },
  month1ChangePercent: {
    label: '1M Chg. (%)',
    labelHTML: 'Price Change (1M)',
    suffix: '%',
  },
  month3ChangePercent: {
    label: '3M Chg. (%)',
    labelHTML: 'Price Change (3M)',
    suffix: '%',
  },
  month6ChangePercent: {
    label: '6M Chg. (%)',
    labelHTML: 'Price Change (6M)',
    suffix: '%',
  },
  week52HighDiff: {
    label: 'Diff. to High (52W)',
    labelHTML: 'Diff. to High (52W)',
    suffix: '%',
  },
  week52LowDiff: {
    label: 'Diff. to Low (52W)',
    labelHTML: 'Diff. to Low (52W)',
    suffix: '%',
  },
  week52NewHigh: {
    label: 'New High (52W)',
    labelHTML: 'New High (52W)',
  },
  week52NewLow: {
    label: 'New Low (52W)',
    labelHTML: 'New Low (52W)',
  },
  year1ChangePercent: {
    label: '1Y Chg. (%)',
    labelHTML: 'Price Change (1Y)',
    suffix: '%',
  },
  year2ChangePercent: {
    label: '2Y Chg. (%)',
    labelHTML: 'Price Change (2Y)',
    suffix: '%',
  },
  year5ChangePercent: {
    label: '5Y Chg. (%)',
    labelHTML: 'Price Change (5Y)',
    suffix: '%',
  },
  ytdChangePercent: {
    label: 'YTD Chg. (%)',
    labelHTML: 'Price Change (YTD)',
    suffix: '%',
  },
  website: {
    label: 'Website',
    labelHTML: 'Website',
  },
  peRatio: {
    label: 'P/E ratio',
    labelHTML: 'P/E ratio',
  },
  pegRatio: {
    label: 'PEG ratio',
    labelHTML: 'PEG ratio',
  },
  beta: {
    label: 'Beta',
    labelHTML: 'Beta',
  },
  earningsPerShareTTM: {
    label: 'EPS',
    labelHTML: 'EPS',
    prefix: '%currency%',
  },
  dividendYield: {
    label: 'Dividend Yield',
    labelHTML: 'Dividend Yield',
    suffix: '%',
  },
  dividendRateTTM: {
    label: 'Dividend Rate (TTM)',
    labelHTML: 'Dividend Rate (TTM)',
  },
  brokers: {
    label: 'Brokers',
    labelHTML: 'Brokers',
  },
  week52HighLow: {
    label: '52 Weeks High/Low',
    labelHTML: '52 Weeks High/Low',
    suffix: '%',
  },
  dividendAmount: {
    label: 'Dividend Amount',
    labelHTML: 'Dividend Amount',
  },
  dividendCurrencyCode: {
    label: 'Dividend Currency',
    labelHTML: 'Dividend Currency',
  },
  dividendDeclaredDate: {
    label: 'Dividend Declared Date',
    labelHTML: 'Dividend Declared Date',
  },
  dividendExDate: {
    label: 'Ex-Dividend Date',
    labelHTML: 'Ex-Dividend Date',
  },
  dividendFlag: {
    label: 'Dividend Payment Method',
    labelHTML: 'Dividend Payment Method',
  },
  dividendFrequency: {
    label: 'Dividends Frequency',
    labelHTML: 'Dividends Frequency',
  },
  dividendPaymentDate: {
    label: 'Dividend Payment Date',
    labelHTML: 'Dividend Payment Date',
  },
  dividendRecordDate: {
    label: 'Dividend Record Date',
    labelHTML: 'Dividend Record Date',
  },
  dividendPayoutRatio: {
    label: 'Dividend Payout Ratio',
    labelHTML: 'Dividend Payout Ratio',
  },
  profitMargin: {
    label: 'Profit Margin',
    labelHTML: 'Profit Margin',
    suffix: '%',
  },
  operatingMarginTTM: {
    label: 'Operating Margin (TTM)',
    labelHTML: 'Operating Margin (TTM)',
    suffix: '%',
  },
  revenueTTM: {
    label: 'Revenue (TTM)',
    labelHTML: 'Revenue (TTM)',
  },
  costOfRevenueTTM: {
    label: 'Cost of Revenue (TTM)',
    labelHTML: 'Cost of Revenue (TTM)',
  },
  grossProfitTTM: {
    label: 'Gross Profit (TTM)',
    labelHTML: 'Gross Profit (TTM)',
  },
  grossMarginTTM: {
    label: 'Gross Margin (TTM)',
    labelHTML: 'Gross Margin (TTM)',
    suffix: '%',
  },
  quarterlyRevenueGrowthYOY: {
    label: 'Quarterly Revenue Growth (YOY)',
    labelHTML: 'Quarterly Revenue<br />Growth (YOY)',
    suffix: '%',
  },
  quarterlyEarningsGrowthYOY: {
    label: 'Quarterly Earnings Growth (YOY)',
    labelHTML: 'Quarterly Earnings<br />Growth (YOY)',
    suffix: '%',
  },
  debtToEquityMRQ: {
    label: 'Debt/Equity (MRQ)',
    labelHTML: 'Debt/Equity (MRQ)',
    suffix: '%',
  },
  returnOnAssetsTTM: {
    label: 'Return on Assets (TTM)',
    labelHTML: 'Return on<br />Assets (TTM)',
    suffix: '%',
  },
  returnOnEquityTTM: {
    label: 'Return on Equity (TTM)',
    labelHTML: 'Return on<br />Equity (TTM)',
    suffix: '%',
  },
  ipoDate: {
    label: 'IPO Date',
    labelHTML: 'IPO Date',
  },
  logoUrl: {
    label: 'Logo',
    labelHTML: 'Logo',
  },
  dividendIncreaseStreak: {
    label: 'Dividend Increase Streak',
    labelHTML: 'Dividend Increase Streak',
  },
};

export const exchangeFlags: {
  [exchange: string]: string;
} = {
  BMEX: '🇪🇸',
  OTCM: '🇺🇸',
  XAMS: '🇳🇱',
  XASE: '🇺🇸',
  XASX: '🇦🇺',
  XBRU: '🇧🇪',
  XCSE: '🇩🇰',
  XFRA: '🇩🇪',
  XHEL: '🇫🇮',
  XHKG: '🇭🇰',
  XLIS: '🇵🇹',
  XLON: '🇬🇧',
  XMIL: '🇮🇹',
  XNAS: '🇺🇸',
  XNYS: '🇺🇸',
  XOSL: '🇳🇴',
  XPAR: '🇫🇷',
  XSAU: '🇸🇦',
  XSTO: '🇸🇪',
  XSWX: '🇨🇭',
};

export const exchangeCodeFlags: {
  [exchange: string]: string;
} = {
  US: '🇺🇸',
  AS: '🇳🇱',
  HK: '🇭🇰',
  MC: '🇪🇸',
  MI: '🇮🇹',
  F: '🇩🇪',
  BR: '🇧🇪',
  LS: '🇵🇹',
  OL: '🇳🇴',
  PA: '🇫🇷',
  LSE: '🇬🇧',
  CO: '🇩🇰',
  HE: '🇫🇮',
  ST: '🇸🇪',
  SW: '🇨🇭',
  SR: '🇸🇦',
  AU: '🇦🇺',
};

export const countryFlags: {
  [countryCode: string]: string;
} = {
  AD: '🇦🇩',
  AE: '🇦🇪',
  AF: '🇦🇫',
  AG: '🇦🇬',
  AI: '🇦🇮',
  AL: '🇦🇱',
  AM: '🇦🇲',
  AO: '🇦🇴',
  AQ: '🇦🇶',
  AR: '🇦🇷',
  AS: '🇦🇸',
  AT: '🇦🇹',
  AU: '🇦🇺',
  AW: '🇦🇼',
  AX: '🇦🇽',
  AZ: '🇦🇿',
  BA: '🇧🇦',
  BB: '🇧🇧',
  BD: '🇧🇩',
  BE: '🇧🇪',
  BF: '🇧🇫',
  BG: '🇧🇬',
  BH: '🇧🇭',
  BI: '🇧🇮',
  BJ: '🇧🇯',
  BL: '🇧🇱',
  BM: '🇧🇲',
  BN: '🇧🇳',
  BO: '🇧🇴',
  BQ: '🇧🇶',
  BR: '🇧🇷',
  BS: '🇧🇸',
  BT: '🇧🇹',
  BV: '🇧🇻',
  BW: '🇧🇼',
  BY: '🇧🇾',
  BZ: '🇧🇿',
  CA: '🇨🇦',
  CC: '🇨🇨',
  CD: '🇨🇩',
  CF: '🇨🇫',
  CG: '🇨🇬',
  CH: '🇨🇭',
  CI: '🇨🇮',
  CK: '🇨🇰',
  CL: '🇨🇱',
  CM: '🇨🇲',
  CN: '🇨🇳',
  CO: '🇨🇴',
  CR: '🇨🇷',
  CU: '🇨🇺',
  CV: '🇨🇻',
  CW: '🇨🇼',
  CX: '🇨🇽',
  CY: '🇨🇾',
  CZ: '🇨🇿',
  DE: '🇩🇪',
  DJ: '🇩🇯',
  DK: '🇩🇰',
  DM: '🇩🇲',
  DO: '🇩🇴',
  DZ: '🇩🇿',
  EC: '🇪🇨',
  EE: '🇪🇪',
  EG: '🇪🇬',
  EH: '🇪🇭',
  ER: '🇪🇷',
  ES: '🇪🇸',
  ET: '🇪🇹',
  EU: '🇪🇺',
  FI: '🇫🇮',
  FJ: '🇫🇯',
  FK: '🇫🇰',
  FM: '🇫🇲',
  FO: '🇫🇴',
  FR: '🇫🇷',
  GA: '🇬🇦',
  GB: '🇬🇧',
  GD: '🇬🇩',
  GE: '🇬🇪',
  GF: '🇬🇫',
  GG: '🇬🇬',
  GH: '🇬🇭',
  GI: '🇬🇮',
  GL: '🇬🇱',
  GM: '🇬🇲',
  GN: '🇬🇳',
  GP: '🇬🇵',
  GQ: '🇬🇶',
  GR: '🇬🇷',
  GS: '🇬🇸',
  GT: '🇬🇹',
  GU: '🇬🇺',
  GW: '🇬🇼',
  GY: '🇬🇾',
  HK: '🇭🇰',
  HM: '🇭🇲',
  HN: '🇭🇳',
  HR: '🇭🇷',
  HT: '🇭🇹',
  HU: '🇭🇺',
  ID: '🇮🇩',
  IE: '🇮🇪',
  IL: '🇮🇱',
  IM: '🇮🇲',
  IN: '🇮🇳',
  IO: '🇮🇴',
  IQ: '🇮🇶',
  IR: '🇮🇷',
  IS: '🇮🇸',
  IT: '🇮🇹',
  JE: '🇯🇪',
  JM: '🇯🇲',
  JO: '🇯🇴',
  JP: '🇯🇵',
  KE: '🇰🇪',
  KG: '🇰🇬',
  KH: '🇰🇭',
  KI: '🇰🇮',
  KM: '🇰🇲',
  KN: '🇰🇳',
  KP: '🇰🇵',
  KR: '🇰🇷',
  KW: '🇰🇼',
  KY: '🇰🇾',
  KZ: '🇰🇿',
  LA: '🇱🇦',
  LB: '🇱🇧',
  LC: '🇱🇨',
  LI: '🇱🇮',
  LK: '🇱🇰',
  LR: '🇱🇷',
  LS: '🇱🇸',
  LT: '🇱🇹',
  LU: '🇱🇺',
  LV: '🇱🇻',
  LY: '🇱🇾',
  MA: '🇲🇦',
  MC: '🇲🇨',
  MD: '🇲🇩',
  ME: '🇲🇪',
  MF: '🇲🇫',
  MG: '🇲🇬',
  MH: '🇲🇭',
  MK: '🇲🇰',
  ML: '🇲🇱',
  MM: '🇲🇲',
  MN: '🇲🇳',
  MO: '🇲🇴',
  MP: '🇲🇵',
  MQ: '🇲🇶',
  MR: '🇲🇷',
  MS: '🇲🇸',
  MT: '🇲🇹',
  MU: '🇲🇺',
  MV: '🇲🇻',
  MW: '🇲🇼',
  MX: '🇲🇽',
  MY: '🇲🇾',
  MZ: '🇲🇿',
  NA: '🇳🇦',
  NC: '🇳🇨',
  NE: '🇳🇪',
  NF: '🇳🇫',
  NG: '🇳🇬',
  NI: '🇳🇮',
  NL: '🇳🇱',
  NO: '🇳🇴',
  NP: '🇳🇵',
  NR: '🇳🇷',
  NU: '🇳🇺',
  NZ: '🇳🇿',
  OM: '🇴🇲',
  PA: '🇵🇦',
  PE: '🇵🇪',
  PF: '🇵🇫',
  PG: '🇵🇬',
  PH: '🇵🇭',
  PK: '🇵🇰',
  PL: '🇵🇱',
  PM: '🇵🇲',
  PN: '🇵🇳',
  PR: '🇵🇷',
  PS: '🇵🇸',
  PT: '🇵🇹',
  PW: '🇵🇼',
  PY: '🇵🇾',
  QA: '🇶🇦',
  RE: '🇷🇪',
  RO: '🇷🇴',
  RS: '🇷🇸',
  RU: '🇷🇺',
  RW: '🇷🇼',
  SA: '🇸🇦',
  SB: '🇸🇧',
  SC: '🇸🇨',
  SD: '🇸🇩',
  SE: '🇸🇪',
  SG: '🇸🇬',
  SH: '🇸🇭',
  SI: '🇸🇮',
  SJ: '🇸🇯',
  SK: '🇸🇰',
  SL: '🇸🇱',
  SM: '🇸🇲',
  SN: '🇸🇳',
  SO: '🇸🇴',
  SR: '🇸🇷',
  SS: '🇸🇸',
  ST: '🇸🇹',
  SV: '🇸🇻',
  SX: '🇸🇽',
  SY: '🇸🇾',
  SZ: '🇸🇿',
  TC: '🇹🇨',
  TD: '🇹🇩',
  TF: '🇹🇫',
  TG: '🇹🇬',
  TH: '🇹🇭',
  TJ: '🇹🇯',
  TK: '🇹🇰',
  TL: '🇹🇱',
  TM: '🇹🇲',
  TN: '🇹🇳',
  TO: '🇹🇴',
  TR: '🇹🇷',
  TT: '🇹🇹',
  TV: '🇹🇻',
  TW: '🇹🇼',
  TZ: '🇹🇿',
  UA: '🇺🇦',
  UG: '🇺🇬',
  UM: '🇺🇲',
  US: '🇺🇸',
  UY: '🇺🇾',
  UZ: '🇺🇿',
  VA: '🇻🇦',
  VC: '🇻🇨',
  VE: '🇻🇪',
  VG: '🇻🇬',
  VI: '🇻🇮',
  VN: '🇻🇳',
  VU: '🇻🇺',
  WF: '🇼🇫',
  WS: '🇼🇸',
  XK: '🇽🇰',
  YE: '🇾🇪',
  YT: '🇾🇹',
  ZA: '🇿🇦',
  ZM: '🇿🇲',
  ZW: '🇿🇼',
};

export const ALL_COUNTRIES: {
  code: string;
  name: string;
}[] = [
  {
    code: 'GS',
    name: 'South Georgia',
  },
  {
    code: 'GD',
    name: 'Grenada',
  },
  {
    code: 'CH',
    name: 'Switzerland',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
  },
  {
    code: 'HU',
    name: 'Hungary',
  },
  {
    code: 'TW',
    name: 'Taiwan',
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    code: 'BB',
    name: 'Barbados',
  },
  {
    code: 'PN',
    name: 'Pitcairn Islands',
  },
  {
    code: 'CI',
    name: 'Ivory Coast',
  },
  {
    code: 'TN',
    name: 'Tunisia',
  },
  {
    code: 'IT',
    name: 'Italy',
  },
  {
    code: 'BJ',
    name: 'Benin',
  },
  {
    code: 'ID',
    name: 'Indonesia',
  },
  {
    code: 'CV',
    name: 'Cape Verde',
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    code: 'LA',
    name: 'Laos',
  },
  {
    code: 'BQ',
    name: 'Caribbean Netherlands',
  },
  {
    code: 'UG',
    name: 'Uganda',
  },
  {
    code: 'AD',
    name: 'Andorra',
  },
  {
    code: 'BI',
    name: 'Burundi',
  },
  {
    code: 'ZA',
    name: 'South Africa',
  },
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'LY',
    name: 'Libya',
  },
  {
    code: 'MX',
    name: 'Mexico',
  },
  {
    code: 'GA',
    name: 'Gabon',
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    code: 'MK',
    name: 'North Macedonia',
  },
  {
    code: 'CN',
    name: 'China',
  },
  {
    code: 'YE',
    name: 'Yemen',
  },
  {
    code: 'BL',
    name: 'Saint Barthélemy',
  },
  {
    code: 'GG',
    name: 'Guernsey',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
  },
  {
    code: 'EG',
    name: 'Egypt',
  },
  {
    code: 'SN',
    name: 'Senegal',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
  },
  {
    code: 'PS',
    name: 'Palestine',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
  },
  {
    code: 'PE',
    name: 'Peru',
  },
  {
    code: 'SG',
    name: 'Singapore',
  },
  {
    code: 'TR',
    name: 'Turkey',
  },
  {
    code: 'AF',
    name: 'Afghanistan',
  },
  {
    code: 'AW',
    name: 'Aruba',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'ZM',
    name: 'Zambia',
  },
  {
    code: 'FI',
    name: 'Finland',
  },
  {
    code: 'NE',
    name: 'Niger',
  },
  {
    code: 'CX',
    name: 'Christmas Island',
  },
  {
    code: 'TK',
    name: 'Tokelau',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
  },
  {
    code: 'RE',
    name: 'Réunion',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
  },
  {
    code: 'KP',
    name: 'North Korea',
  },
  {
    code: 'MU',
    name: 'Mauritius',
  },
  {
    code: 'MS',
    name: 'Montserrat',
  },
  {
    code: 'VI',
    name: 'United States Virgin Islands',
  },
  {
    code: 'CO',
    name: 'Colombia',
  },
  {
    code: 'GR',
    name: 'Greece',
  },
  {
    code: 'HR',
    name: 'Croatia',
  },
  {
    code: 'MA',
    name: 'Morocco',
  },
  {
    code: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'AQ',
    name: 'Antarctica',
  },
  {
    code: 'NL',
    name: 'Netherlands',
  },
  {
    code: 'SD',
    name: 'Sudan',
  },
  {
    code: 'FJ',
    name: 'Fiji',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
  },
  {
    code: 'NP',
    name: 'Nepal',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
  },
  {
    code: 'GE',
    name: 'Georgia',
  },
  {
    code: 'PK',
    name: 'Pakistan',
  },
  {
    code: 'MC',
    name: 'Monaco',
  },
  {
    code: 'BW',
    name: 'Botswana',
  },
  {
    code: 'LB',
    name: 'Lebanon',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
  },
  {
    code: 'YT',
    name: 'Mayotte',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
  },
  {
    code: 'QA',
    name: 'Qatar',
  },
  {
    code: 'MG',
    name: 'Madagascar',
  },
  {
    code: 'IN',
    name: 'India',
  },
  {
    code: 'SY',
    name: 'Syria',
  },
  {
    code: 'ME',
    name: 'Montenegro',
  },
  {
    code: 'SZ',
    name: 'Eswatini',
  },
  {
    code: 'PY',
    name: 'Paraguay',
  },
  {
    code: 'SV',
    name: 'El Salvador',
  },
  {
    code: 'UA',
    name: 'Ukraine',
  },
  {
    code: 'IM',
    name: 'Isle of Man',
  },
  {
    code: 'NA',
    name: 'Namibia',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
  },
  {
    code: 'GL',
    name: 'Greenland',
  },
  {
    code: 'DE',
    name: 'Germany',
  },
  {
    code: 'KH',
    name: 'Cambodia',
  },
  {
    code: 'IQ',
    name: 'Iraq',
  },
  {
    code: 'TF',
    name: 'French Southern and Antarctic Lands',
  },
  {
    code: 'SE',
    name: 'Sweden',
  },
  {
    code: 'CU',
    name: 'Cuba',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    code: 'RU',
    name: 'Russia',
  },
  {
    code: 'MY',
    name: 'Malaysia',
  },
  {
    code: 'ST',
    name: 'São Tomé and Príncipe',
  },
  {
    code: 'CY',
    name: 'Cyprus',
  },
  {
    code: 'CA',
    name: 'Canada',
  },
  {
    code: 'MW',
    name: 'Malawi',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
  },
  {
    code: 'ES',
    name: 'Spain',
  },
  {
    code: 'SI',
    name: 'Slovenia',
  },
  {
    code: 'OM',
    name: 'Oman',
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: 'MO',
    name: 'Macau',
  },
  {
    code: 'SM',
    name: 'San Marino',
  },
  {
    code: 'LS',
    name: 'Lesotho',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
  },
  {
    code: 'SX',
    name: 'Sint Maarten',
  },
  {
    code: 'IS',
    name: 'Iceland',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
  },
  {
    code: 'AR',
    name: 'Argentina',
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    code: 'NR',
    name: 'Nauru',
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  {
    code: 'EH',
    name: 'Western Sahara',
  },
  {
    code: 'DM',
    name: 'Dominica',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
  },
  {
    code: 'AU',
    name: 'Australia',
  },
  {
    code: 'TH',
    name: 'Thailand',
  },
  {
    code: 'HT',
    name: 'Haiti',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
  },
  {
    code: 'HN',
    name: 'Honduras',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
  },
  {
    code: 'PF',
    name: 'French Polynesia',
  },
  {
    code: 'BY',
    name: 'Belarus',
  },
  {
    code: 'LV',
    name: 'Latvia',
  },
  {
    code: 'PW',
    name: 'Palau',
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
  },
  {
    code: 'PH',
    name: 'Philippines',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
  },
  {
    code: 'DK',
    name: 'Denmark',
  },
  {
    code: 'CM',
    name: 'Cameroon',
  },
  {
    code: 'GN',
    name: 'Guinea',
  },
  {
    code: 'BH',
    name: 'Bahrain',
  },
  {
    code: 'SR',
    name: 'Suriname',
  },
  {
    code: 'CD',
    name: 'DR Congo',
  },
  {
    code: 'SO',
    name: 'Somalia',
  },
  {
    code: 'CZ',
    name: 'Czechia',
  },
  {
    code: 'NC',
    name: 'New Caledonia',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
  },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    code: 'TG',
    name: 'Togo',
  },
  {
    code: 'VG',
    name: 'British Virgin Islands',
  },
  {
    code: 'KE',
    name: 'Kenya',
  },
  {
    code: 'NU',
    name: 'Niue',
  },
  {
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  {
    code: 'RW',
    name: 'Rwanda',
  },
  {
    code: 'EE',
    name: 'Estonia',
  },
  {
    code: 'RO',
    name: 'Romania',
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    code: 'GY',
    name: 'Guyana',
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
  },
  {
    code: 'VN',
    name: 'Vietnam',
  },
  {
    code: 'UY',
    name: 'Uruguay',
  },
  {
    code: 'VA',
    name: 'Vatican City',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
  },
  {
    code: 'AT',
    name: 'Austria',
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
  },
  {
    code: 'PA',
    name: 'Panama',
  },
  {
    code: 'FM',
    name: 'Micronesia',
  },
  {
    code: 'IE',
    name: 'Ireland',
  },
  {
    code: 'CW',
    name: 'Curaçao',
  },
  {
    code: 'GF',
    name: 'French Guiana',
  },
  {
    code: 'NO',
    name: 'Norway',
  },
  {
    code: 'AX',
    name: 'Åland Islands',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
  },
  {
    code: 'ER',
    name: 'Eritrea',
  },
  {
    code: 'TZ',
    name: 'Tanzania',
  },
  {
    code: 'KR',
    name: 'South Korea',
  },
  {
    code: 'JO',
    name: 'Jordan',
  },
  {
    code: 'MR',
    name: 'Mauritania',
  },
  {
    code: 'LT',
    name: 'Lithuania',
  },
  {
    code: 'UM',
    name: 'United States Minor Outlying Islands',
  },
  {
    code: 'SK',
    name: 'Slovakia',
  },
  {
    code: 'AO',
    name: 'Angola',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
  },
  {
    code: 'MD',
    name: 'Moldova',
  },
  {
    code: 'ML',
    name: 'Mali',
  },
  {
    code: 'FK',
    name: 'Falkland Islands',
  },
  {
    code: 'AM',
    name: 'Armenia',
  },
  {
    code: 'WS',
    name: 'Samoa',
  },
  {
    code: 'JE',
    name: 'Jersey',
  },
  {
    code: 'JP',
    name: 'Japan',
  },
  {
    code: 'BO',
    name: 'Bolivia',
  },
  {
    code: 'CL',
    name: 'Chile',
  },
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'BM',
    name: 'Bermuda',
  },
  {
    code: 'SC',
    name: 'Seychelles',
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    code: 'GT',
    name: 'Guatemala',
  },
  {
    code: 'EC',
    name: 'Ecuador',
  },
  {
    code: 'MQ',
    name: 'Martinique',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
  },
  {
    code: 'MT',
    name: 'Malta',
  },
  {
    code: 'GM',
    name: 'Gambia',
  },
  {
    code: 'NG',
    name: 'Nigeria',
  },
  {
    code: 'BS',
    name: 'Bahamas',
  },
  {
    code: 'XK',
    name: 'Kosovo',
  },
  {
    code: 'KW',
    name: 'Kuwait',
  },
  {
    code: 'MV',
    name: 'Maldives',
  },
  {
    code: 'SS',
    name: 'South Sudan',
  },
  {
    code: 'IR',
    name: 'Iran',
  },
  {
    code: 'AL',
    name: 'Albania',
  },
  {
    code: 'BR',
    name: 'Brazil',
  },
  {
    code: 'RS',
    name: 'Serbia',
  },
  {
    code: 'BZ',
    name: 'Belize',
  },
  {
    code: 'MM',
    name: 'Myanmar',
  },
  {
    code: 'BT',
    name: 'Bhutan',
  },
  {
    code: 'VE',
    name: 'Venezuela',
  },
  {
    code: 'LR',
    name: 'Liberia',
  },
  {
    code: 'JM',
    name: 'Jamaica',
  },
  {
    code: 'PL',
    name: 'Poland',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
  },
  {
    code: 'BN',
    name: 'Brunei',
  },
  {
    code: 'KM',
    name: 'Comoros',
  },
  {
    code: 'GU',
    name: 'Guam',
  },
  {
    code: 'TO',
    name: 'Tonga',
  },
  {
    code: 'KI',
    name: 'Kiribati',
  },
  {
    code: 'GH',
    name: 'Ghana',
  },
  {
    code: 'TD',
    name: 'Chad',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
  },
  {
    code: 'MF',
    name: 'Saint Martin',
  },
  {
    code: 'MN',
    name: 'Mongolia',
  },
  {
    code: 'PT',
    name: 'Portugal',
  },
  {
    code: 'AS',
    name: 'American Samoa',
  },
  {
    code: 'CG',
    name: 'Republic of the Congo',
  },
  {
    code: 'BE',
    name: 'Belgium',
  },
  {
    code: 'IL',
    name: 'Israel',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
  },
  {
    code: 'NI',
    name: 'Nicaragua',
  },
  {
    code: 'AI',
    name: 'Anguilla',
  },
  {
    code: 'EU',
    name: 'Europe',
  },
];

export const ALL_CURRENCIES_FLAGS: {
  [currencyCode: string]: string;
} = {
  AED: '🇦🇪',
  AFN: '🇦🇫',
  ALL: '🇦🇱',
  AMD: '🇦🇲',
  ANG: '🇳🇱',
  AOA: '🇦🇴',
  ARS: '🇦🇷',
  AUD: '🇦🇺',
  AWG: '🇦🇼',
  AZN: '🇦🇿',
  BAM: '🇧🇦',
  BBD: '🇧🇧',
  BDT: '🇧🇩',
  BGN: '🇧🇬',
  BHD: '🇧🇭',
  BIF: '🇧🇮',
  BMD: '🇧🇲',
  BND: '🇧🇳',
  BOB: '🇧🇴',
  BRL: '🇧🇷',
  BSD: '🇧🇸',
  BTN: '🇧🇹',
  BWP: '🇧🇼',
  BYN: '🇧🇾',
  BYR: '🇧🇾',
  BZD: '🇧🇿',
  CAD: '🇨🇦',
  CDF: '🇨🇩',
  CHF: '🇨🇭',
  CLF: '🇨🇱',
  CLP: '🇨🇱',
  CNY: '🇨🇳',
  COP: '🇨🇴',
  CRC: '🇨🇷',
  CUC: '🇨🇺',
  CUP: '🇨🇺',
  CVE: '🇨🇻',
  CZK: '🇨🇿',
  DJF: '🇩🇯',
  DKK: '🇩🇰',
  DOP: '🇩🇴',
  DZD: '🇩🇿',
  EGP: '🇪🇬',
  ERN: '🇪🇷',
  ETB: '🇪🇹',
  EUR: '🇪🇺',
  FJD: '🇫🇯',
  FKP: '🇫🇰',
  GBP: '🇬🇧',
  GEL: '🇬🇪',
  GHS: '🇬🇭',
  GIP: '🇬🇮',
  GMD: '🇬🇲',
  GNF: '🇬🇳',
  GTQ: '🇬🇹',
  GYD: '🇬🇾',
  HKD: '🇭🇰',
  HNL: '🇭🇳',
  HRK: '🇭🇷',
  HTG: '🇭🇹',
  HUF: '🇭🇺',
  IDR: '🇮🇩',
  ILS: '🇮🇱',
  INR: '🇮🇳',
  IQD: '🇮🇶',
  IRR: '🇮🇷',
  ISK: '🇮🇸',
  JMD: '🇯🇲',
  JOD: '🇯🇴',
  JPY: '🇯🇵',
  KES: '🇰🇪',
  KGS: '🇰🇬',
  KHR: '🇰🇭',
  KMF: '🇰🇲',
  KPW: '🇰🇵',
  KRW: '🇰🇷',
  KWD: '🇰🇼',
  KYD: '🇰🇾',
  KZT: '🇰🇿',
  LAK: '🇱🇦',
  LBP: '🇱🇧',
  LKR: '🇱🇰',
  LRD: '🇱🇷',
  LSL: '🇱🇸',
  LTL: '🇱🇹',
  LVL: '🇱🇻',
  LYD: '🇱🇾',
  MAD: '🇲🇦',
  MDL: '🇲🇩',
  MGA: '🇲🇬',
  MKD: '🇲🇰',
  MMK: '🇲🇲',
  MNT: '🇲🇳',
  MOP: '🇲🇴',
  MRO: '🇲🇷',
  MUR: '🇲🇺',
  MVR: '🇲🇻',
  MWK: '🇲🇼',
  MXN: '🇲🇽',
  MYR: '🇲🇾',
  MZN: '🇲🇿',
  NAD: '🇳🇦',
  NGN: '🇳🇬',
  NIO: '🇳🇮',
  NOK: '🇳🇴',
  NPR: '🇳🇵',
  NZD: '🇳🇿',
  OMR: '🇴🇲',
  PAB: '🇵🇦',
  PEN: '🇵🇪',
  PGK: '🇵🇬',
  PHP: '🇵🇭',
  PKR: '🇵🇰',
  PLN: '🇵🇱',
  PYG: '🇵🇾',
  QAR: '🇶🇦',
  RON: '🇷🇴',
  RSD: '🇷🇸',
  RUB: '🇷🇺',
  RWF: '🇷🇼',
  SAR: '🇸🇦',
  SBD: '🇸🇧',
  SCR: '🇸🇨',
  SDG: '🇸🇩',
  SEK: '🇸🇪',
  SGD: '🇸🇬',
  SHP: '🇸🇭',
  SLE: '🇸🇱',
  SLL: '🇸🇱',
  SOS: '🇸🇴',
  SRD: '🇸🇷',
  STD: '🇸🇹',
  SYP: '🇸🇾',
  SZL: '🇸🇿',
  THB: '🇹🇭',
  TJS: '🇹🇯',
  TMT: '🇹🇲',
  TND: '🇹🇳',
  TOP: '🇹🇴',
  TRY: '🇹🇷',
  TTD: '🇹🇹',
  TWD: '🇹🇼',
  TZS: '🇹🇿',
  UAH: '🇺🇦',
  UGX: '🇺🇬',
  USD: '🇺🇸',
  UYU: '🇺🇾',
  UZS: '🇺🇿',
  VEF: '🇻🇪',
  VES: '🇻🇪',
  VND: '🇻🇳',
  VUV: '🇻🇺',
  WST: '🇼🇸',
  XAF: '🇨🇲',
  XCD: '🇦🇮',
  XOF: '🇧🇯',
  XPF: '🇵🇫',
  YER: '🇾🇪',
  ZAR: '🇿🇦',
  ZMW: '🇿🇲',
  ZWL: '🇿🇼',
};
